@import "src/styles/table";

$numCellWidthForMobile: 40px;
$activateCellWidthForMobile: 70px;
$warningCellWidthForMobile: 70px;
$contentCellWidthForMobile: 180px;
$writerCellWidthForMobile: 180px;
$funcCellWidthForMobile: 40px;

$scrollTableWidthForMobile: $numCellWidthForMobile + $activateCellWidthForMobile + $warningCellWidthForMobile + $contentCellWidthForMobile + $writerCellWidthForMobile + $funcCellWidthForMobile;

.numCell {
  width: 8%;
}

.activateCell {
  width: 10%;
}

.warningCell {
  width: 10%;
}

.contentCell {
  width: 50%;
}

.writerCell {
  width: 20%;
}

.funcCell {
  width: 10%;
}

@media (max-width: 998px) {
  .scrollTable {
    width: $scrollTableWidthForMobile;
  }

  .numCell {
    width: $numCellWidthForMobile;
  }

  .activateCell {
    width: $activateCellWidthForMobile;
  }

  .warningCell {
    width: $warningCellWidthForMobile;
  }

  .contentCell {
    width: $contentCellWidthForMobile;
  }

  .writerCell {
    width: $writerCellWidthForMobile;
  }

  .funcCell {
    width: $funcCellWidthForMobile;
  }
}

:export {
  scrollTableWidthForMobile: $scrollTableWidthForMobile;
}