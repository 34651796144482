@import "src/styles/table";

.cont-mb {
  display: flex;
  flex-wrap: wrap;

  div {
    padding: 0;
  }

  .box-id {
    font-size: 0.8rem;
    width: 20%;
  }

  .box-date {
    text-align: right;
    font-size: 0.8rem;
    width: 100%;
    color: #b6b6b6;
  }

  .box-email {
    width: 80%;
    color: #17a2b8;
    text-align: left;
  }

  .box-sent-count {
    width: 20%;
    color: gray;
    text-align: right;
  }

  .box-content {
    padding-top: 10px;
    width: 100%;
    text-align: left;
    font-size: 0.8rem;
    color: #9a9a9a;
    line-height: 1rem;
  }
}
