@import "src/styles/table";
@import "src/styles/colors";

.box-title {
  display: flex;
}

.cont-mb {
  padding: 10px 0;
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid $border-color;
  line-height: 1rem;

  .box-email {
    width: 100%;
    color: $sub-title-color;
    margin-bottom: 10px;
    text-align: left;
  }

  .box-sm {
    padding: 5px;
    font-size: 0.7rem;
    width: 25%;
  }

  .color-gray {
    color: gray;
  }
}


.cont-popover {
  padding: 15px;
  width: 540px;
  height: 370px;

  .email {
    padding: 0 0 5px 0;
    color: $sub-title-color;
    font-size: 1rem;
    border-bottom: 1px solid $border-color;
  }

  .box-period {
    border-top: 1px solid $border-color;
    padding: 10px;
    display: flex;
    justify-content: space-between;

    .label {
      line-height: 35px;
    }

    button {
      display: block;
      width: 80px;
    }
  }

  .box-btn {
    border-top: 1px solid $border-color;
    display: flex;
    padding: 10px;

    .warning {
      font-size: 0.9rem;
      width: calc(100% - 60px);
      color: #9a9a9a;
    }

    .btns {
      width: 60px;
      text-align: right;
    }
  }
}
