@import '~bootstrap/dist/css/bootstrap.min.css';
@import "~flag-icon-css/css/flag-icon.min.css";

@font-face {
  font-family: Nato Sans;
  src: url("../assets/fonts/nato-sans/NotoSansKR-Regular.otf");
}

* {
  font-family: "Nato Sans", sans-serif !important;
}

// 인풋 박스 포커스때 테두리 삭제
input:focus {
  outline: none !important;
}

*:focus {
  outline: none !important;
}

button:focus {
  outline: none !important;
}


a {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}


@media(max-width: 998px) {
  .def-text {
    font-size: 0.8rem;
  }
}
