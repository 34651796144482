@import "src/styles/table";

$deleteCellWidthForMobile: 50px;
$codeCellWidthForMobile: 80px;
$taxCellWidthForMobile: 70px;
$commissionCellWidthForMobile: 70px;
$apiCellWidthForMobile: 150px;
$issuerCellWidthForMobile: 200px;

$scrollTableWidthForMobile: $deleteCellWidthForMobile + $codeCellWidthForMobile + $taxCellWidthForMobile + $apiCellWidthForMobile + $commissionCellWidthForMobile + $issuerCellWidthForMobile;

.deleteCell {
  width: 20%;
}

.codeCell {
  width: 20%;
}

.taxCell {
  width: 20%;
}

.commissionCell {
  width: 20%;
}

.apiCell {
  width: 20%;
}

.issuerCell {
  width: 20%;
}

.textLeft {
  text-align: left !important;
}

@media (max-width: 998px) {
  .scrollTable {
    width: $scrollTableWidthForMobile;
  }

  .deleteCell {
    width: $deleteCellWidthForMobile;
  }

  .codeCell {
    width: $codeCellWidthForMobile;
  }

  .taxCell {
    width: $taxCellWidthForMobile;
  }

  .commissionCell {
    width: $commissionCellWidthForMobile;
  }

  .apiCell {
    width: $apiCellWidthForMobile;
  }

  .issuerCell {
    width: $issuerCellWidthForMobile;
  }
}

:export {
  scrollTableWidthForMobile: $scrollTableWidthForMobile;
}