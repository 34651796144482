.contSearchBar {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;

  .inputEmail {
    width: 30%;
  }

  .btn {
    margin-left: 10px;
    text-align: right;
    width: 80px;
  }
}
