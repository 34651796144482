@import "src/styles/table";
@import "src/styles/colors";

.cont-alert {
  padding: 10px;
  border-top: 1px solid $border-color;
  cursor: default;

  &:hover {
    background: #f7fbff;
  }

  .email {
    color: $sub-title-color;
    margin-bottom: 5px;
  }

  .nm {
    display: flex;

    div:nth-child(1) {
      color: #9a9a9a;
      width: 50%;
    }

    div:nth-child(2) {
      text-align: right;
      width: 50%;
    }
  }
}

.hr {
  border-color: #9a9a9a;
}
