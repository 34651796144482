@import "src/styles/table";
@import "src/styles/colors";

.table-mb {
  font-size: 0.8rem;
  margin-bottom: 0;


  .checkbox {
    width: 10%;
    text-align: left;
  }

  .email {
    width: 50%;
    color: $sub-title-color;
    text-overflow: ellipsis;
    overflow: hidden;

  }

  .commission {
    width: 40%;
    text-align: right;

    span {
      color: #9a9a9a;
    }
  }
}

.checked {
  background: #eaf4ff;
}
