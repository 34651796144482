@import "src/styles/table";

$numCellWidthForMobile: 50px;
$userCellWidthForMobile: 205px;
$newCellWidthForMobile: 60px;

$scrollTableWidthForMobile: $numCellWidthForMobile + $userCellWidthForMobile + $newCellWidthForMobile;

.cont-list {
  height: 500px
}

.numCell {
  width: 10%;
}

.userCell {
  width: 70%;
  display: flex;
  justify-content: flex-start;
}

.timeCell {
  width: 15%;
}

.newCell {
  width: 5%;
}

.textLeft {
  text-align: left !important;
}

.textRight {
  text-align: right !important;
}

@media (max-width: 998px) {
  .numCell {
    width: $numCellWidthForMobile;
  }

  .userCell {
    width: $userCellWidthForMobile;
  }

  .newCell {
    width: $newCellWidthForMobile;
  }
}

:export {
  scrollTableWidthForMobile: $scrollTableWidthForMobile;
}