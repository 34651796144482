$chartWidth: 100%;
$chartBrowserHeight: 300px;
$chartMobileHeight: 220px;

.chart {
  width: $chartWidth;
  height: $chartBrowserHeight;
}

@media (max-width: 998px) {
  .chart {
    height: $chartMobileHeight;
  }
}

:export {
  chartWidth: $chartWidth;
  chartBrowserHeight: $chartBrowserHeight;
  chartMobileHeight: $chartMobileHeight;
}