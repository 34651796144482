@import "src/styles/table";


.table-report {
  .col-sms-fee {
    width: 50%;
  }

  .col-sent-sms {
    width: 50%;
  }
}
