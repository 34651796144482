@import "src/styles/colors";

.cont-title {
  display: flex;

  .half {
    width: 50%;
  }
}

.cont-pager {
  $width-pager: 100px;
  display: flex;

  div:nth-child(1) {
    width: $width-pager;
  }

  div:nth-child(2) {
    width: calc(100% - #{$width-pager});
  }
}

.input-chat {
  display: flex;
  justify-content: space-between;


  input {
    width: calc(100% - 110px);
  }

  button {
    width: 100px;
  }
}


.cont-scroll {
  background: #f8f8f8;
  border-radius: 5px;
  border: 1px solid $border-color;
  padding: 15px;
  margin-bottom: 15px;
  overflow: hidden;
  height: 600px !important;
}

.cont-chat {
  display: flex;
  flex-direction: column-reverse;
}


.cont-chat-box {
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  width: 60%;
  padding: 10px;
  border: 1px solid #e2e2e2;
  margin-bottom: 20px;
  background: white;


  &.box-user {
    margin-right: auto;
    background: #effff0;
  }

  &.box-admin {
    margin-left: auto;

  }

  .chat-writer {
    font-size: 1rem;
    margin-bottom: 10px;
    font-weight: 900;
    color: gray;
  }

  .created-at {
    text-align: right;
    font-size: 0.8rem;
    margin-top: 10px;
    color: #bfbfbf;
  }
}

@media (max-width: 998px) {
  .cont-scroll {
    height: 230px !important;
  }

  .cont-chat-box {
    width: 85% !important;

    .chat-writer {
      font-size: 0.7rem;
    }

    .chat-content {
      font-size: 0.9rem;
    }

    .created-at {
      font-size: 0.7rem;
    }
  }

}
