@import "src/styles/table";

$funcCellWidthForMobile: 50px;
$codeCellWidthForMobile: 80px;
$recyleCellWidthForMobile: 70px;
$vatCellWidthForMobile: 50px;
$apiCellWidthForMobile: 120px;
$commissionCellWidthForMobile: 120px;
$issuerCellWidthForMobile: 200px;

$scrollTableWidthForMobile: $funcCellWidthForMobile + $codeCellWidthForMobile + $recyleCellWidthForMobile + $vatCellWidthForMobile + $apiCellWidthForMobile + $commissionCellWidthForMobile + $issuerCellWidthForMobile;

.funcCell {
  width: 10%;
}

.codeCell {
  width: 15%;
}

.recyleCell {
  width: 8%;
}

.vatCell {
  width: 8%;
}

.apiCell {
  width: 19%;
}

.commissionCell {
  width: 20%;
}

.issuerCell {
  width: 20%;
}

.textLeft {
  text-align: left !important;
}

@media (max-width: 998px) {
  .scrollTable {
    width: $scrollTableWidthForMobile;
  }

  .funcCell {
    width: $funcCellWidthForMobile;
  }

  .codeCell {
    width: $codeCellWidthForMobile;
  }

  .recyleCell {
    width: $recyleCellWidthForMobile;
  }

  .vatCell {
    width: $vatCellWidthForMobile;
  }

  .apiCell {
    width: $apiCellWidthForMobile;
  }

  .commissionCell {
    width: $commissionCellWidthForMobile;
  }

  .issuerCell {
    width: $issuerCellWidthForMobile;
  }
}

:export {
  scrollTableWidthForMobile: $scrollTableWidthForMobile;
}