@import "src/styles/table";
@import "src/styles/colors";


.cont-mb {
  border-top: 1px solid $border-color;
  padding-top: 10px;
  display: flex;
  flex-wrap: wrap;
  font-size: 0.8rem;
  margin-bottom: 10px;

  .date {
    width: 40%;
    color: $sub-title-color;
  }

  .send-total {
    width: 20%;
    text-align: right;
    font-weight: 800;
    color: #9a9a9a;
  }

  .changed-point {
    width: 40%;
    text-align: right;
    font-weight: 800;
  }

  .content {
    margin-top: 10px;
    padding: 5px;
    font-size: 0.8rem;
    color: #616161;
  }
}
