@import "src/styles/table";
@import "src/styles/colors";

.table-mb {
  .email {
    width: 50%;
    color: $sub-title-color;
  }

  .date {
    width: 50%;
    text-align: right;
  }
}
