.contSearchBar {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;

  .select {
    width: 10%;
  }

  .input {
    width: 30%;
    margin-left: 10px;
  }

  .btn {
    width: 80px;
    margin-left: 10px;
  }
}


@media (max-width: 998px) {
  .contSearchBar {
    .select {
      width: 30%;
    }

    .input {
      width: 50%;
    }
  }
}