@import "src/styles/table";

$carrierCellWidthForMobile: 50px;
$receiveCellWidthForMobile: 95px;
$sentCellWidthForMobile: 110px;
$contentCellWidthForMobile: 150px;
$dateCellWidthForMobile: 130px;
$funcCellWidthForMobile: 50px;

$scrollTableWidthForMobile: $carrierCellWidthForMobile + $receiveCellWidthForMobile + $sentCellWidthForMobile + $contentCellWidthForMobile + $dateCellWidthForMobile + $funcCellWidthForMobile;

.carrierCell {
  width: 10%;
}

.receiveCell {
  width: 15%;
}

.sentCell {
  width: 15%;
}

.contentCell {
  width: 30%;
}

.dateCell {
  width: 20%;
}

.funcCell {
  width: 10%;
}

.textLeft {
  text-align: left !important;
}

.textRight {
  text-align: right !important;
}

@media (max-width: 998px) {
  .carrierCell {
    width: $carrierCellWidthForMobile;
  }

  .receiveCell {
    width: $receiveCellWidthForMobile;
  }

  .sentCell {
    width: $sentCellWidthForMobile;
  }

  .contentCell {
    width: $contentCellWidthForMobile;
  }

  .dateCell {
    width: $dateCellWidthForMobile;
  }

  .funcCell {
    width: $funcCellWidthForMobile;
  }
}

:export {
  scrollTableWidthForMobile: $scrollTableWidthForMobile;
}