@import "src/styles/table";

$dateCellWidthForMobile: 120px;
$statusCellWidthForMobile: 80px;
$valueCellWidthForMobile: 100px;
$pointCellWidthForMobile: 120px;
$depositCellWidthForMobile: 120px;
$funcCellWidthForMobile: 100px;

$scrollTableWidthForMobile: $dateCellWidthForMobile + $statusCellWidthForMobile + $valueCellWidthForMobile + $pointCellWidthForMobile + $depositCellWidthForMobile + $funcCellWidthForMobile;

.dateCell {
  width: 20%;
}

.statusCell {
  width: 16%;
}

.valueCell {
  width: 16%;
}

.pointCell {
  width: 16%;
}

.depositCell {
  width: 16%;
}

.funcCell {
  width: 16%;
}

.textRight {
  text-align: right !important;
}

@media (max-width: 998px) {
  .scrollTable {
    width: $scrollTableWidthForMobile;
  }

  .dateCell {
    width: $dateCellWidthForMobile;
  }

  .statusCell {
    width: $statusCellWidthForMobile;
  }

  .valueCell {
    width: $valueCellWidthForMobile;
  }

  .pointCell {
    width: $pointCellWidthForMobile;
  }

  .depositCell {
    width: $depositCellWidthForMobile;
  }

  .funcCell {
    width: $funcCellWidthForMobile;
  }
}

:export {
  scrollTableWidthForMobile: $scrollTableWidthForMobile;
}