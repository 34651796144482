@import "../../../../../styles/table";

.nameCell {
  width: 40%;
}

.valueCell {
  width: 60%;
}
.textRight {
  text-align: right !important;
}

@media (max-width: 998px) {

}