@import "colors";

.table {
  display: flex;
  border-top: 1px solid $border-color;
  overflow: hidden;

  div {
    padding: 10px;
    text-align: center;
    line-height: 35px;
    overflow: hidden;
    text-wrap: normal;
    text-overflow: ellipsis;
    white-space: nowrap;;
    color: $content-color;

    select {
      margin-top: 3px;
    }

    button {
    }
  }

  .header {
    color: black;
    font-weight: 900;
  }

  .mb-hidden {
    display: block;
  }

  .space {
    flex-grow: 1;
  }

  &.hover {
    &:hover {
      background: #f6f6f6;
    }
  }

  input {
    margin-top: 3px;
  }

  &.pointer {
    cursor: pointer;
  }


  &.border-bottom {
    border-bottom: 1px solid $border-color;
  }

  &.margin-bottom {
    margin-bottom: 15px;
  }
}

.table-mb {
  border-top: 1px solid $border-color;
  display: flex;
  flex-wrap: wrap;
  line-height: 40px;
  font-size: 0.8rem;
  justify-content: space-between;
  padding: 0 5px 0 5px;

  div {
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

@media (max-width: 998px) {
  .table {
    div {
      padding: 5px;
      font-size: 0.8rem;
    }
    .mb-hidden {
      display: none;
    }
  }
}
