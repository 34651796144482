@import "src/styles/table";

.dateCell {
  width: 20%;
}

.variableCell {
  width: 10%;
}

.pointCell {
  width: 15%;
}

.userCell {
  width: 27.5%;
}

.adminCell {
  width: 27.5%;
}

.textRight {
  text-align: right !important;
}

@media (max-width: 998px) {
  .dateCell {
    width: 110px !important;
  }

  .variableCell {
    width: 70px !important;
  }

  .pointCell {
    width: 100px !important;
  }

  .userCell {
    width: 200px !important;
  }

  .adminCell {
    width: 200px !important;
  }

  .scrollTable {
    width: (110+70+100+200+200)px;
  }
}