@import "src/styles/table";
@import "src/styles/colors";

.cont-mb {
  border-top: 1px solid $border-color;
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  line-height: 40px;
  font-size: 0.8rem;
  justify-content: space-between;
  margin-bottom: 20px;

  .activate {
    width: 20%;
  }

  .api-nm {
    width: 60%;
    color: $sub-title-color;
    font-size: 0.9rem;
    font-weight: 800;
  }

  .api-sms-fee {
    width: 20%;
    text-align: right;
    font-weight: 800;
    font-size: 0.9rem;
  }

  .half-width {
    width: calc(50% - 5px);
  }

}
