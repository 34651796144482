@import 'src/styles/table';
@import 'src/styles/colors';

.cont-mb {
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid $border-color;
  padding-top: 10px;
  color: black;

  div {
    margin-bottom: 15px;
  }

  .email {
    width: 80%;
    font-size: 1.1rem;
    color: $sub-title-color;
  }

  .grade {
    text-align: right;
    width: 20%;
  }

  .label {
    font-size: 0.9rem;
    width: 30%;
  }

  .label-value {
    color: $content-color;
    width: 70%;
    text-align: right;
    font-size: 0.9rem;
  }
}
