@import "src/styles/table";

.flex {
  display: flex;
}

.cont-title {
  display: flex;
  .half {
    width: 50%;
  }
}
