@import "src/styles/colors";
@import "src/styles/webSizing";

.box-join {
  margin: 20px auto;
  padding: 20px 30px 20px 30px;
  border-radius: 3px;
  box-shadow: 3px 3px 3px #d0d0d0;
  border: 1px solid #f5f5f5;
  background: #ffffff;
  width: 90%;
  max-width: 450px;

  p {
    margin-top: 20px;
    margin-bottom: 5px !important;
    font-size: 0.8rem;
  }

  .box-terms {
    display: flex;

    label {
      display: block;
      flex-grow: 1;
    }

    .check-box {
      width: 20px;
      height: 20px;
      margin-top: 2px;
      margin-right: 10px;
    }
  }
}

.copyright {
  text-align: center;
  margin: 30px 0 30px 0;
  font-size: 0.8rem;
  color: #a8a8a8
}
