@import "src/styles/table";

$activateCellWithForMobile: 80px;
$lineCellWithForMobile: 120px;
$priceCellWithForMobile: 80px;
$nameCellWithForMobile: 150px;
$numberOfMessageWithForCell: 100px;

$scrollTableWidthForMobile: $activateCellWithForMobile + $lineCellWithForMobile + $priceCellWithForMobile + $nameCellWithForMobile + $numberOfMessageWithForCell;

.activateCell {
  width: 20%;
}

.lineCell {
  width: 20%;
}

.priceCell {
  width: 20%;
}

.nameCell {
  width: 20%;
}

.numberOfMessageCell {
  width: 20%;
}

@media (max-width: 998px) {
  .scrollTable {
    width: $scrollTableWidthForMobile;
  }

  .activateCell {
    width: $activateCellWithForMobile;
  }

  .lineCell {
    width: $lineCellWithForMobile;
  }

  .priceCell {
    width: $priceCellWithForMobile;
  }

  .nameCell {
    width: $nameCellWithForMobile;
  }

  .numberOfMessageCell {
    width: $numberOfMessageWithForCell;
  }
}

:export {
  scrollTableWidthForMobile: $scrollTableWidthForMobile;
}