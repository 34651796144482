@import "src/styles/table";
@import "src/styles/colors";

.table-mb {
  line-height: 30px;

  .status {
    width: 15%;
  }
  .email {
    width: 60%;
    color: $sub-title-color;
  }

  .date {
    width: 25%;
    text-align: right;
    color: #9a9a9a;
  }

  .calc {
    width: 50%;
    color: gray;
  }

  .commission {
    width: 50%;
    font-weight: 800;
    text-align: right;
  }
}
