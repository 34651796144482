@import "src/styles/table";
@import "src/styles/colors";

.cont-mb {
  border-top: 1px solid $border-color;
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  font-size: 0.8rem;
  padding-top: 10px;

  div {
    margin-bottom: 5px;
  }

  .date {
    color: #c7c7c7;
    width: 100%;
    text-align: right;
  }

  .def {
    width: 20%;
    text-align: right;
  }

  .quarter {
    font-size: 0.8rem;
    font-weight: 800;
    width: 25%;
  }

  .memo {
    padding: 5px;
    font-size: 0.8rem;
    border: 1px solid #f1f1f1;
    text-align: left;
    width: 100%;
    border-radius: 3px;
    margin: 10px auto 10px auto;
    color: $content-color;
  }

  .red {
    color: crimson;
  }

  .green {
    color: #64bb64;
  }

  .gray {
    color: #b6b6b6;
  }
}
