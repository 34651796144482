@import "src/styles/table";
@import "src/styles/table";

.table-mb {
  line-height: 25px;

  .nm {
    color: $sub-title-color;
    width: 30%;
    font-weight: 800;
  }

  .amount {
    width: 70%;
    text-align: right;
    font-weight: 800;
  }

  .label {
    width: 15%;
    color: #9a9a9a;
    font-size: 0.7rem;
  }

  .label-value {
    width: 35%;
    text-align: right;
    font-size: 0.7rem;
    color: #767676;
    font-weight: 700;
  }

  .date {
    width: 100%;
    font-size: 0.7rem;
    color: #9a9a9a;
  }

  .func {
    padding-top: 15px;
    padding-bottom: 10px;
    width: 100%;
    text-align: right;

    button {
      font-size: 0.6rem;
      margin-left: 10px;
    }
  }
}

.box-deposit {
  .amount {
    line-height: 0 !important;
  }

  .info {
    line-height: 0 !important;
    color: #b5b5b5;
    font-size: 0.8rem;

    span {
      margin-right: 5px;
    }
  }
}
