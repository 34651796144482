@import "src/styles/table";

.numCell {
  width: 33%;
}

.dateCell {
  width: 33%;
}

.priceCell {
  width: 34%;
}

.sumCell {
  width: 66%;
}

.textRight {
  text-align: right !important;
}