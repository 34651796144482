@import "src/styles/colors";

.title {
  font-size: 1.2rem;
  margin-bottom: 15px;
  color: $title-color;
}

.sm-title {
  font-size: 1rem;
  margin-bottom: 15px;
  color: $title-color;
}

@media(max-width: 998px) {
  .title {
    font-size: 1rem;
  }

  .sm-title {
    font-size: 0.9rem;
  }
}

