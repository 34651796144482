$height: 60px;

.cont {
  height: $height;
  line-height: $height;
  color: #ffffff;
  font-size: 1.2rem;
  padding: 0 10px 0 20px;
  cursor: pointer;

  &.menu {
    display: flex;
    font-size: 1rem;
    color: #979797;

    svg {
      margin-top: 19px;
      margin-right: 15px;
      color: #6363db;
    }
  }

  &.menu:hover {
    color: #cacaca;
  }

  .title {
    flex-grow: 1;
  }
}
