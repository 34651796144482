@import "src/styles/table";

$numCellWidthForMobile: 40px;
$statusCellWidthForMobile: 50px;
$subjectCellWidthForMobile: 180px;
$writerCellWidthForMobile: 180px;
$dateCellWidthForMobile: 60px;
$modifyCellWidthForMobile: 40px;
$deleteCellWidthForMobile: 40px;

$scrollTableWidthForMobile: $numCellWidthForMobile + $statusCellWidthForMobile + $subjectCellWidthForMobile + $writerCellWidthForMobile + $dateCellWidthForMobile + $modifyCellWidthForMobile + $deleteCellWidthForMobile;

.numCell {
  width: 8%;
}

.statusCell {
  width: 8%;
}

.subjectCell {
  width: 43%;
}

.writerCell {
  width: 15%;
}

.dateCell {
  width: 10%;
}

.modifyCell {
  width: 8%;
}

.deleteCell {
  width: 8%;
}

.textLeft {
  text-align: left !important;
}

@media (max-width: 998px) {
  .scrollTable {
    width: $scrollTableWidthForMobile;
  }

  .numCell {
    width: $numCellWidthForMobile;
  }

  .statusCell {
    width: $statusCellWidthForMobile;
  }

  .subjectCell {
    width: $subjectCellWidthForMobile;
  }

  .writerCell {
    width: $writerCellWidthForMobile;
  }

  .dateCell {
    width: $dateCellWidthForMobile;
  }

  .modifyCell {
    width: $modifyCellWidthForMobile;
  }

  .deleteCell {
    width: $deleteCellWidthForMobile;
  }
}

:export {
  scrollTableWidthForMobile: $scrollTableWidthForMobile;
}