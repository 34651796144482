@import "src/styles/table";

.cont-picker {
  position: relative;

  .box-picker {
    position: absolute;
    left: 0;
    top: 0;
  }
}
