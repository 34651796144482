@import "src/styles/table";

.not-found {
  height: 60px;
  line-height: 60px;
  text-align: center;
}

.funcCell {
  width: 10%;
}

.carrierCell {
  width: 10%;
}

.phoneCell {
  width: 60%;
}

.statusCell {
  width: 20%;
}

@media (max-width: 998px) {
  .funcCell {
    width: 9%;
  }

  .carrierCell {
    width: 18%;
  }

  .phoneCell {
    width: 43%;
  }

  .statusCell {
    width: 30%;

    padding-top: 8px !important;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 1.3 !important;
  }
}