@import "src/styles/table";

.cont-contents {
  width: 100%;
  overflow: hidden;
  padding: 10px;

  img {
    width: 100%;
  }
}
