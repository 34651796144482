@import "src/styles/table";
@import "src/styles/colors";

.table-mb {
  font-size: 0.7rem;

  .checkbox {
    width: 10%;
    text-align: left;
  }

  .email {
    width: 30%;
    color: $sub-title-color;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

  }

  .calc {
    width: 30%;
    text-align: right;
  }

  .commission {
    width: 30%;
    text-align: right;

    span {
      color: #9a9a9a;
    }
  }
}

.checked {
  background: #eaf4ff;
}
