@import "src/styles/table";
@import "src/styles/colors";

.cont-mb {
  border-top: 1px solid $border-color;
  padding-top: 10px;
  display: flex;
  flex-wrap: wrap;
  font-size: 0.8rem;
  margin-bottom: 10px;

  div {
    line-height: 20px;
  }

  .info {
    width: 15%;
  }

  .date {
    width: 65%;
    color: #9a9a9a;
  }

  .delete {
    width: 20%;
    text-align: right;
  }

  .msg {
    width: 100%;
    font-weight: 800;
  }

  .error {
    color: rgb(97, 26, 21);
  }

  .warning {
    color: rgb(102, 60, 0);
  }

  .info {
    color: rgb(13, 60, 97);
  }

  .success {
    color: rgb(30, 70, 32);
  }

}
