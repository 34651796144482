@import "src/styles/table";

$valueCellWidthForMobile: 120px;
$priceCellWidthForMobile: 80px;
$pointCellWidthForMobile: 120px;
$commissionCellWidthForMobile: 80px;
$depositCellWidthForMobile: 120px;
$funcCellWidthForMobile: 100px;

$scrollTableWidthForMobile: $valueCellWidthForMobile + $priceCellWidthForMobile + $pointCellWidthForMobile + $commissionCellWidthForMobile + $depositCellWidthForMobile + $funcCellWidthForMobile;

.valueCell {
  width: 20%;
}

.priceCell {
  width: 16%;
}

.pointCell {
  width: 16%;
}

.commissionCell {
  width: 16%;
}

.depositCell {
  width: 16%;
}

.funcCell {
  width: 16%;
}

.textRight {
  text-align: right !important;
}

@media (max-width: 998px) {
  .scrollTable {
    width: $scrollTableWidthForMobile;
  }

  .valueCell {
    width: $valueCellWidthForMobile;
  }

  .priceCell {
    width: $priceCellWidthForMobile;
  }

  .pointCell {
    width: $pointCellWidthForMobile;
  }

  .commissionCell {
    width: $commissionCellWidthForMobile;
  }

  .depositCell {
    width: $depositCellWidthForMobile;
  }

  .funcCell {
    width: $funcCellWidthForMobile;
  }
}

:export {
  scrollTableWidthForMobile: $scrollTableWidthForMobile;
}