
.paper {
  width: 250px;
  background: #1a1a27 !important;
}

.contents {
  background: #e6e8ef;
  min-height: 100vh;
}
