.cont-background {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: -1;

  .back-black {
    position: fixed;
    background: #000000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  img {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
  }

  .filter {
    background: #000000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  }
}
