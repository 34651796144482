@import "src/styles/table";

$requestDateCellWidthForMobile: 120px;
$caculateDateCellWidthForMobile: 120px;
$statusCellWidthForMobile: 80px;
$userCellWidthForMobile: 180px;
$wayCellWidthForMobile: 80px;
$pointCellWidthForMoibile: 80px;

$scrollTableWidthForMobile: $requestDateCellWidthForMobile + $caculateDateCellWidthForMobile + $statusCellWidthForMobile + $userCellWidthForMobile + $wayCellWidthForMobile + $pointCellWidthForMoibile;

.requestDateCell {
  width: 15%;
}

.caculateDateCell {
  width: 15%;
}

.statusCell {
  width: 10%;
}

.userCell {
  width: 30%;
}

.wayCell {
  width: 10%;
}

.pointCell {
  width: 20%;
}

.textRight {
  text-align: right !important;
}

@media (max-width: 998px) {
  .scrollTable {
    width: $scrollTableWidthForMobile;
  }

  .requestDateCell {
    width: $requestDateCellWidthForMobile;
  }

  .caculateDateCell {
    width: $caculateDateCellWidthForMobile;
  }

  .statusCell {
    width: $statusCellWidthForMobile;
  }

  .userCell {
    width: $userCellWidthForMobile;
  }

  .wayCell {
    width: $wayCellWidthForMobile;
  }

  .pointCell {
    width: $pointCellWidthForMoibile;
  }
}

:export {
  scrollTableWidthForMobile: $scrollTableWidthForMobile;
}