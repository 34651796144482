.miniBox {
  width: 25%;
  padding: 15px;
}

.divideBlock {
  width: 100%;
  height: 25px;
}

@media (max-width: 998px) {
  .miniBox {
    &:nth-child(2n-1) {
      padding: 5px 5px 5px 15px;
    }

    &:nth-child(2n) {
      padding: 5px 15px 5px 5px;
    }

    width: 50% !important;
  }
}