@import "src/styles/colors";
@import "src/styles/webSizing";

.box-login {
  width: 90%;
  max-width: 450px;
  margin: 0 auto 20px auto;
  background: white;
  padding: 20px;
  border-radius: 3px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.02);

  h3 {
    text-align: center;
  }

  .btn {
    margin-bottom: 10px;
  }
}
