@import "src/styles/table";


$userCellWidthForMobile: 150px;
$badgeCellWidthForMobile: 100px;
$valueCellWidthForMobile: 100px;
$pointCellWidthForMobile: 100px;
$paymentCellWidthForMobile: 100px;

$scrollTableWidthForMobile: $userCellWidthForMobile + $badgeCellWidthForMobile + $valueCellWidthForMobile + $pointCellWidthForMobile + $paymentCellWidthForMobile;

.userCell {
  width: 30%;
}

.badgeCell {
  width: 15%;
}

.valueCell {
  width: 15%;
}

.pointCell {
  width: 20%;
}

.paymentCell {
  width: 20%;
}

.textRight {
  text-align: right !important;
}

@media (max-width: 998px) {
  .scrollTable {
    width: $scrollTableWidthForMobile;
  }

  .userCell {
    width: $userCellWidthForMobile;
  }

  .badgeCell {
    width: $badgeCellWidthForMobile;
  }

  .valueCell {
    width: $valueCellWidthForMobile;
  }

  .pointCell {
    width: $pointCellWidthForMobile;
  }

  .paymentCell {
    width: $paymentCellWidthForMobile;
  }
}

:export {
  scrollTableWidthForMobile: $scrollTableWidthForMobile;
}