.cont {
  display: flex;
  margin-bottom: 10px;
  margin-top: 10px;

  .label {
    width: 100px;
    text-align: center;
    line-height: 34px;
  }

  .picker {
    width: 200px;
  }

}
